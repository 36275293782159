var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"productsModal"},[_c('b-modal',{attrs:{"title":_vm.$t('keys.products.product'),"no-close-on-backdrop":"","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('b-tabs-header',{attrs:{"tabs":_vm.getTitles,"activeTab":_vm.activeTab},on:{"clickTab":_vm.handleClickTab,"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event},"close":function($event){_vm.modalActive = false}}})]},proxy:true},{key:"default",fn:function(){return [_c('div',[_c('b-overlay',{attrs:{"show":_vm.showLoader}},[_c('validation-observer',{ref:"validation"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 0),expression:"activeTab === 0"}],attrs:{"title":_vm.getTabTitle}},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"name",attrs:{"autofocus":""},model:{value:(_vm.addObject.name),callback:function ($$v) {_vm.$set(_vm.addObject, "name", $$v)},expression:"addObject.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('general.category')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.productSubcategories,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.product_category_id),callback:function ($$v) {_vm.$set(_vm.addObject, "product_category_id", $$v)},expression:"addObject.product_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('general.additions')}},[_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.productOrderOptions,"reduce":function (item) { return item.id; },"multiple":""},model:{value:(_vm.addObject.order_option_group_ids),callback:function ($$v) {_vm.$set(_vm.addObject, "order_option_group_ids", $$v)},expression:"addObject.order_option_group_ids"}})],1),(_vm.course_menus && _vm.course_menus.length > 0 && _vm.courseMenuConfiguration && _vm.courseMenuConfiguration.course_menu_enabled && _vm.$hasTierPermission(_vm.Tiers.Premium))?_c('b-form-group',{attrs:{"label":_vm.$t('course_menu.course_menu')}},[_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.course_menus,"reduce":function (item) { return item.id; }},model:{value:(_vm.addObject.course_menu_id),callback:function ($$v) {_vm.$set(_vm.addObject, "course_menu_id", $$v)},expression:"addObject.course_menu_id"}})],1):_vm._e(),(!_vm.editing)?_c('b-form-group',{attrs:{"label":_vm.$t('keys.sale_price')}},[_c('div',{staticClass:"d-inline-flex w-100"},[_c('div',[_c('b-input-group-prepend',{staticClass:"checkboxPrice",attrs:{"is-text":""}},[_c('b-form-checkbox',{on:{"change":_vm.changeNoPredefinedPrice},model:{value:(_vm.salePriceEnabled),callback:function ($$v) {_vm.salePriceEnabled=$$v},expression:"salePriceEnabled"}})],1)],1),_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":_vm.$t('keys.sale_price'),"rules":_vm.salePriceEnabled ? 'required|decimalCustom:2|mustBePositive:true' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"inputPrice",attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"disabled":!_vm.salePriceEnabled,"type":"number","value":_vm.salePrice},on:{"input":_vm.changeSalePrice}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1925741088)})],1)])]):_vm._e(),(_vm.salePriceEnabled === false)?_c('div',{staticClass:"d-flex mb-1"},[_c('div',[_vm._v(_vm._s(_vm.$t("table_fields.entered_price_can_be_negative")))]),_c('b-form-checkbox',{staticClass:"ml-2 red",attrs:{"switch":""},model:{value:(_vm.addObject.allow_negative_price),callback:function ($$v) {_vm.$set(_vm.addObject, "allow_negative_price", $$v)},expression:"addObject.allow_negative_price"}})],1):_vm._e(),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('keys.color')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('colorSelector',{attrs:{"product-name":_vm.addObject.name},model:{value:(_vm.addObject.color),callback:function ($$v) {_vm.$set(_vm.addObject, "color", $$v)},expression:"addObject.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.$t("keys.products.disabled")))]),_c('b-form-checkbox',{staticClass:"ml-2 red",attrs:{"switch":""},model:{value:(_vm.addObject.disabled),callback:function ($$v) {_vm.$set(_vm.addObject, "disabled", $$v)},expression:"addObject.disabled"}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 1 && _vm.$hasTierPermission(_vm.Tiers.Standard, _vm.Tiers.Premium)),expression:"activeTab === 1 && $hasTierPermission(Tiers.Standard, Tiers.Premium)"}]},[_c('b-form-group',{attrs:{"label":_vm.$t('general.add_ingredient')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.add_ingredient')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","filter":_vm.fuseSearch,"appendToBody":"","options":_vm.newIngredientsFiltered,"selectable":function (ingredient) { return !ingredient.disabled; }},on:{"option:selected":_vm.ingredientChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var ingredient_code = ref.ingredient_code;
var disabled = ref.disabled;
return [_c('div',{class:{'disabledIngredient': disabled}},[_vm._v(_vm._s(ingredient_code)+" - "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var ingredient_code = ref.ingredient_code;
return [_c('div',[_vm._v(_vm._s(ingredient_code)+" - "+_vm._s(name))])]}}],null,true),model:{value:(_vm.selected_ingredient),callback:function ($$v) {_vm.selected_ingredient=$$v},expression:"selected_ingredient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"4"}},[_c('b-button',{staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.addModal.open()}}},[_c('feather-icon',{staticClass:"mx-50",attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.add_ingredient'))+" ")],1)],1),_c('b-col',{staticClass:"p-0 pl-3",attrs:{"cols":"2"}},[_c('b-button',{attrs:{"variant":"outline-primary","disabled":!_vm.selected_ingredient},on:{"click":function($event){return _vm.cloneIngredient(_vm.selected_ingredient)}}},[_c('feather-icon',{staticClass:"mx-50",attrs:{"icon":"CopyIcon"}})],1)],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('form_data.quantity')}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('validation-provider',{staticStyle:{"width":"100%"},attrs:{"name":_vm.$t('form_data.quantity'),"rules":_vm.getQuantityRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.selected_quantity),callback:function ($$v) {_vm.selected_quantity=$$v},expression:"selected_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('form_data.quantity')+'-select'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"width":"150px","margin-left":"1rem"},attrs:{"disabled":!_vm.selected_ingredient,"appendToBody":"","label":"name","options":_vm.amount_types},model:{value:(_vm.selected_amount_type),callback:function ($$v) {_vm.selected_amount_type=$$v},expression:"selected_amount_type"}}),_c('small',{staticClass:"text-danger",staticStyle:{"width":"150px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"d-flex justify-content-center "},[_c('b-button',{staticClass:"w-25",attrs:{"variant":"primary"},on:{"click":_vm.addIngredient}},[_c('feather-icon',{staticClass:" justify-content-center",attrs:{"icon":"ArrowDownIcon"}})],1)],1),(_vm.addObject.ingredients)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('general.ingredients')))]),_c('Table',{ref:"ingredientsTable",staticClass:"mt-1",attrs:{"name":"ingredients","columnDefs":_vm.columnDefs,"rowData":_vm.addObject.ingredients},on:{"deleteRow":_vm.removeIngredient,"edit":_vm.editIngredient}})],1):_vm._e(),(_vm.editing)?_c('div',[_c('b-button',{staticClass:"mb-1 mt-3",attrs:{"variant":"primary","block":""},on:{"click":function($event){_vm.isCollapseOpen = !_vm.isCollapseOpen}}},[_vm._v(_vm._s(_vm.$t("products.update_ingredient_stock_from_date")))]),_c('b-collapse',{attrs:{"role":"tabpanel"},model:{value:(_vm.isCollapseOpen),callback:function ($$v) {_vm.isCollapseOpen=$$v},expression:"isCollapseOpen"}},[_c('div',{staticClass:"text-bold cardBackground p-2",staticStyle:{"border-radius":"18px"}},[_c('b-form-group',{attrs:{"label":_vm.$t('general.date_from')}},[_c('date-picker',{staticClass:"relative w-100",attrs:{"locale":_vm.currentLocale,"first-day-of-week":2,"model-config":_vm.modelConfig,"popover":_vm.popoverOptions,"popover-direction":"top","masks":{input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY']}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}],null,false,35058453),model:{value:(_vm.dateToRegenerateIngredients),callback:function ($$v) {_vm.dateToRegenerateIngredients=$$v},expression:"dateToRegenerateIngredients"}})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","disabled":_vm.dateToRegenerateIngredients === null},on:{"click":_vm.recalculateStock}},[_vm._v(" "+_vm._s(_vm.$t("products.calculate_again"))+" ")])],1)],1)])],1):_vm._e()],1)])],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.showLoader},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.save')))])],1)])]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})],1),_c('AddIngredient',{ref:"addModal",attrs:{"ingredients":_vm.ingredients,"ingredient_categories":_vm.ingredient_categories},on:{"itemAdded":_vm.ingredientAdded}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }